/* Base styles */
.App {
  text-align: center;
  background-color: white;
}

header {
  height: 14vh; /* 10% of viewport height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%; /* Use percentages for padding */
}

.logo {
  width: 8vw; /* 8% of viewport width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 2vw; /* Adjust spacing for smaller screens */
}

.leftH, .rightH {
  display: flex;
  align-items: center;
  gap: 1vw; /* Adjust gap for smaller screens */
}

/* Main window */
.mainWindow {
  margin-top: 2vh; /* Adjust margin for smaller screens */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Link styles */
.link {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* Media queries for responsive design */
@media screen and (max-width: 668px) {
  header {
    padding: 0 2%; /* Adjust for larger screens */
  }

  .logo {
    width: 6vw; /* Adjust logo size for larger screens */
    margin-right: 1vw; /* Adjust spacing for larger screens */
  }

  .leftH, .rightH {
    gap: 2vw; /* Adjust gap for larger screens */
  }

  .mainWindow {
    margin-top: 3vh; /* Adjust margin for larger screens */
  }
}

/* Media query for smaller screens */
@media screen and (max-width: 375px) {
  header {
    padding: 0 2%; 
   
    width: auto;
    margin-left: 5px;
    /* Adjust for larger screens */
  }

  .logo {
    width: 6vw; /* Adjust logo size for larger screens */
    margin-right: 1vw; /* Adjust spacing for larger screens */
  }

  .leftH, .rightH {
    gap: 2vw; /* Adjust gap for larger screens */
  }

  .mainWindow {
    margin-top: 3vh; 
    margin-left: 1px;/* Adjust margin for larger screens */
    width: 100%; /* Adjust margin for larger screens */
  }
}






/* Media query for smaller screens */
@media screen and (max-width: 370px) {
  header {
    padding: 0 2%; 
    margin-left: 10px;

    width: auto;
    /* Adjust for larger screens */
  }

  .logo {
    width: 3vw; /* Adjust logo size for larger screens */
    margin-right: 1vw; /* Adjust spacing for larger screens */
  }

  .leftH, .rightH {
    gap: 2vw; /* Adjust gap for larger screens */
  }

  .mainWindow {
    margin-top: 3vh; /* Adjust margin for larger screens */

    width: 100%; /* Adjust margin for larger screens */
  }
}



/* Media query for smaller screens */
@media screen and (max-width: 360px) {
  header {
    padding: 0 2%; 
    margin-left: 30px;

    width: auto;
    /* Adjust for larger screens */
  }

  .logo {
    width: 3vw; /* Adjust logo size for larger screens */
    margin-right: 1vw; /* Adjust spacing for larger screens */
  }

  .leftH, .rightH {
    gap: 2vw; /* Adjust gap for larger screens */
  }

  .mainWindow {
    margin-top: 3vh; /* Adjust margin for larger screens */
    margin-left: 7px;
    width: 100%; /* Adjust margin for larger screens */
  }
}

/* Media query for smaller screens */
@media screen and (max-width: 350px) {
  header {
    padding: 0 2%; 
    margin-left: 2rem;

    width: auto;
    /* Adjust for larger screens */
  }

  .logo {
    width: 3vw; /* Adjust logo size for larger screens */
    margin-right: 1vw; /* Adjust spacing for larger screens */
  }

  .leftH, .rightH {
    gap: 2vw; /* Adjust gap for larger screens */
  }

  .mainWindow {
    margin-top: 3vh; /* Adjust margin for larger screens */
    margin-left: 10px;
    width: 100%; /* Adjust margin for larger screens */
  }
}

  /* Media query for smaller screens */
  @media screen and (max-width: 340px) {
    header {
      padding: 0 2%; 
      margin-left: 3rem;
  
      width: auto;
      /* Adjust for larger screens */
    }
  
    .logo {
      width: 3vw; /* Adjust logo size for larger screens */
      margin-right: 1vw; /* Adjust spacing for larger screens */
    }
  
    .leftH, .rightH {
      gap: 2vw; /* Adjust gap for larger screens */
    }
  
    .mainWindow {
      margin-top: 3vh; /* Adjust margin for larger screens */
      margin-left: 18px;
      width: 100%; /* Adjust margin for larger screens */
    }
  }
