/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header container */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo */
.logo {
  width: 80px;
  height: auto;
  margin-bottom: 10px; /* Add space below the logo for smaller screens */
}

/* Navigation links */
.link {
  text-decoration: none;
  color: #333;
  position: relative; /* Add this for positioning of pseudo-element */
  transition: color 0.3s ease-in-out;
}

.link:hover {
  color: #92B085;
}

.link::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #83A680;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

.link:hover::before {
  transform: scaleX(1);
}

.headerItem {
  margin: 0 10px;
  padding: 5px 0px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.headerItem:hover {
  background-color: #f0f0f0;
}

/* Connect button */
.connectButton,
.disConnectButton {
  padding: 3px 15px;
  height: 40px;
  position: relative;
  background-color: #9CB788;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.disConnectButton {
  font-size: 18px;
}

.connectButton:hover,
.disConnectButton:hover {
  background-color: #92B085;
  color: white;
}


/* Media queries for responsive design */
@media screen and (max-width: 900px) {
  header {
    flex-direction: column;
    text-align: center;
    padding: 10px; /* Increase padding for better spacing */
  }

  .logo {
    width: 60px; /* Adjust logo size for smaller screens */
    margin-bottom: 10px; /* Add space below the logo for smaller screens */
  }

  .rightH {
    margin-top: 10px;
  }

  .headerItem {
    margin: 5px; /* Adjust margin for smaller screens */
  }

  .connectButton,
  .disConnectButton {
    padding: 3px 10px; /* Adjust padding for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}


