/* Import the Google Fonts in the <head> of your HTML document */

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default styles for html and body */
html,
body {
  padding: 0;
  margin: 0;
  background-color: white;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

/* Define styles for links */
a {
  color: inherit;
  text-decoration: none;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  
  body {
    color: white;
    background: #1F242E;
  }
}
